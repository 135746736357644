import { defineStore } from "pinia";

interface LiveTradeInterface {
    id: number;
    offerId?: string;
    code?: string;
    type: string;
    target_type?: string;
    target_id?: string;
    steps: {
        current: number;
        total: number;
    };
    status: "pending" | "completed" | "failed" | "escrow";
}

export const useTradesStore = defineStore("trades", () => {
    const ws = useSocketStore()();
    const auth = useAuthStore();

    const trades = ref<LiveTradeInterface[]>([]);
    const isExpanded = ref(false);
    const isShown = computed(() => trades.value.length > 0);

    const toggleExpanded = () => {
        isExpanded.value = !isExpanded.value;
    };

    ws.register("live-trades:index", (data: { trades: LiveTradeInterface[] }) => {
        trades.value = data.trades.toSorted((a, b) => b.id - a.id);

        isExpanded.value = trades.value.length > 0;
    });

    ws.register("live-trades:show", ({ data }: { data: { trade: LiveTradeInterface } }) => {
        const index = trades.value.findIndex((t) => t.id === data.trade.id);

        if (index !== -1) {
            trades.value[index] = data.trade;
        } else {
            isExpanded.value = true;
            trades.value.unshift(data.trade);
        }

        if ((["completed", "failed"] as LiveTradeInterface["status"][]).includes(data.trade.status)) {
            setTimeout(() => {
                trades.value = trades.value.filter((t) => t.id !== data.trade.id);
            }, 5_000);
        }
    });

    if (getCurrentInstance() && auth.isLogged) {
        ws.handleNamedRestore("live-trades", () => ws.socket?.emit("live-trades:index"), undefined, true);
    }

    return {
        trades,
        isExpanded,
        isShown,
        toggleExpanded
    };
});
