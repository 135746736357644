import revive_payload_client_4sVQNw7RlN from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/opt/buildhome/repo/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import sentry_client_VdOeuw25Mb from "/opt/buildhome/repo/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/buildhome/repo/.nuxt/sentry-client-config.mjs";
import slideovers_X7whXyrIWR from "/opt/buildhome/repo/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/opt/buildhome/repo/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/opt/buildhome/repo/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/opt/buildhome/repo/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/opt/buildhome/repo/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import sounds_7noHXeIa7n from "/opt/buildhome/repo/node_modules/@vueuse/sound/dist/runtime/plugins/sounds.ts";
import i18n_yfWm7jX06p from "/opt/buildhome/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_splashScreen_Ksz8JcSU2b from "/opt/buildhome/repo/plugins/01.splashScreen.ts";
import _02_auth_client_87yXP6zQIF from "/opt/buildhome/repo/plugins/02.auth.client.ts";
import _03_sideload_A3U7E37nsk from "/opt/buildhome/repo/plugins/03.sideload.ts";
import _04_sideloadParallel_t3SvGJuEzD from "/opt/buildhome/repo/plugins/04.sideloadParallel.ts";
import audio_client_RoOUsnPeFy from "/opt/buildhome/repo/plugins/audio.client.ts";
import bus_client_bku2rtFYG7 from "/opt/buildhome/repo/plugins/bus.client.ts";
import chartjs_1wC9lAM0Tp from "/opt/buildhome/repo/plugins/chartjs.ts";
import crisp_client_DCubnCdDP5 from "/opt/buildhome/repo/plugins/crisp.client.ts";
import dragon_drop_vue_hGNDNkfAfh from "/opt/buildhome/repo/plugins/dragon-drop-vue.ts";
import error_handler_kEP5FliEXj from "/opt/buildhome/repo/plugins/error-handler.ts";
import image_client_ACXpeewADH from "/opt/buildhome/repo/plugins/image.client.ts";
import manifestUpdate_client_1Ows8mMikt from "/opt/buildhome/repo/plugins/manifestUpdate.client.ts";
import referral_client_bIRkrgMbmL from "/opt/buildhome/repo/plugins/referral.client.ts";
import vueImageZoomer_8Lu0junJr9 from "/opt/buildhome/repo/plugins/vueImageZoomer.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  sounds_7noHXeIa7n,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  _01_splashScreen_Ksz8JcSU2b,
  _02_auth_client_87yXP6zQIF,
  _03_sideload_A3U7E37nsk,
  _04_sideloadParallel_t3SvGJuEzD,
  audio_client_RoOUsnPeFy,
  bus_client_bku2rtFYG7,
  chartjs_1wC9lAM0Tp,
  crisp_client_DCubnCdDP5,
  dragon_drop_vue_hGNDNkfAfh,
  error_handler_kEP5FliEXj,
  image_client_ACXpeewADH,
  manifestUpdate_client_1Ows8mMikt,
  referral_client_bIRkrgMbmL,
  vueImageZoomer_8Lu0junJr9
]