import { ModalsLeaderboardWin, ModalsPopupTradeSent } from "#components";
import { defineStore } from "pinia";
import { io, Socket } from "socket.io-client";
import customParser from "socket.io-msgpack-parser";

export const useSocketStore = (namespace: string = "") => {
    return defineStore(`socket-${namespace}`, () => {
        const socket = ref<Socket | null>(null);
        const connected = ref<boolean>(false);
        const auth = useAuthStore();
        const toast = useToast();
        const customToast = useToast();
        const route = useRoute();
        const router = useRouter();
        const { t, locale } = useNuxtApp().$i18n;
        const config = useRuntimeConfig();
        const configData = ref<AppConfig>();
        const modal = useModal();
        const mightBeErroring = ref<boolean>(false); // indicate if it might have failed
        const userCount = ref<number>(0);
        const handleNamedCallbacks = ref<
            Record<
                string,
                {
                    callback: () => any;
                    stopCallback?: () => any;
                }
            >
        >({});
        const noConnectionToastIds = ref<any[]>([]);

        const uniqueId = useCookie("socket-id");

        const liveGame = ref<
            Record<string, { active: boolean; amount: number }>
        >({
            coinflip: { active: false, amount: 0 },
            jackpot: { active: false, amount: 0 },
            battles: { active: false, amount: 0 }
        });

        const load = async () => {
            if (socket.value && socket.value.connected) {
                return;
            }

            if (!uniqueId.value) {
                uniqueId.value =
                    Math.random().toString(36).substring(2, 15) +
                    Math.random().toString(36).substring(2, 15);
            }

            // use websocket as long as we don't use sticky sessions yet
            socket.value = io(config.public.apiUrl + `${namespace}`, {
                transports: ["websocket"],
                parser:
                    config.public.appEnv === "local" ? undefined : customParser,
                auth: {
                    token: auth.token
                },
                query: {
                    "x-socket-id": uniqueId.value,
                    "x-locale": locale.value
                }
            });

            if (config.public.devMode && socket.value) {
                const oldSocketEmit = socket.value?.emit;

                socket.value!.emit = function (
                    event: any,
                    ...args: any[]
                ): any {
                    console.log("[SOCKET] Emitting", event, ...args);
                    oldSocketEmit?.apply(this, [event, ...args]);
                };
            }

            socket.value.on("connect", async () => {
                connected.value = true;
                mightBeErroring.value = true;

                for (const key in handleNamedCallbacks.value) {
                    handleNamedCallbacks.value[key].callback();
                }

                if (noConnectionToastIds.value.length) {
                    toast.add({
                        title: t("connected"),
                        description: t("notification.connectedToServer"),
                        color: "green"
                    });

                    noConnectionToastIds.value.forEach(toast.remove);
                    noConnectionToastIds.value = [];

                    // if (!import.meta.dev) {
                    //     window.location.reload();
                    // }
                }

                socket.value?.emit("live-games:index");

                await auth.load();

                if (auth.user) {
                    socket.value!.emit("leaderboard:check");
                }
            });

            socket.value.on("disconnect", () => {
                connected.value = false;

                const errorToast = toast.add({
                    title: t("connectionLost"),
                    description: t("notification.waitWhileReconnect"),
                    color: "red",
                    timeout: 0,
                    actions: [
                        {
                            label: "Refresh",
                            click: () => window.location.reload()
                        }
                    ]
                });

                noConnectionToastIds.value.push(errorToast.id);
            });

            socket.value.on("error", error => {
                if (import.meta.dev) {
                    console.error(error);
                }

                if (error.message) {
                    toast.add({
                        title: error.message,
                        color: "red"
                    });
                }
            });

            socket.value.on("connect_error", () => {
                mightBeErroring.value = true;

                const errorToast = toast.add({
                    title: t("connectionError"),
                    description: t("notification.couldntConnectServer"),
                    color: "red"
                });

                noConnectionToastIds.value.push(errorToast.id);
            });

            // todo: better toast handling?
            socket.value.on("notification", notification =>
                customToast.add(notification)
            );

            socket.value.on("leaderboard:end", () =>
                socket.value?.emit("leaderboard:check")
            );

            socket.value.on(
                "leaderboard:check",
                ({ reward }: { reward: LeaderboardPeriodRewardInterface }) => {
                    if (!reward) {
                        return;
                    }

                    modal.open(ModalsLeaderboardWin, { reward });
                }
            );

            // todo: make this generic
            socket.value.on(
                "user:message",
                async ({ action, data }: { action: string; data?: any }) => {
                    switch (action) {
                        case "tradeOffer":
                            await modal.close();
                            setTimeout(
                                () => modal.open(ModalsPopupTradeSent, data),
                                500
                            );
                            break;

                        case "coins":
                            if (auth.user) {
                                auth.user.coins = data.coins ?? auth.user.coins;
                                auth.user.wager_rest =
                                    data.wagerRest ?? auth.user.wager_rest;
                                auth.user.vault_coins =
                                    data.vaultCoins ?? auth.user.vault_coins;
                                auth.user.xmas_coins =
                                    data.xmasCoins ?? auth.user.xmas_coins;
                                auth.user.commission_coins =
                                    data.commissionCoins ??
                                    auth.user.commission_coins;
                            }
                            break;

                        case "coinflip:win":
                            // own page modal will handle it at the right timing
                            if (
                                route.path.includes("coinflip") &&
                                modal.isOpen.value
                            ) {
                                return;
                            }

                            await auth.checkForUnclaimed();
                            break;

                        case "levelUp":
                            if (auth.user) {
                                auth.user.level = data.level;
                                auth.user.nextLevel = data.nextLevel;
                                auth.user.xp = data.xp;
                            }
                            break;

                        case "toast_success":
                            toast.add({
                                title: data.title,
                                description: data.description,
                                color: "green"
                            });
                            break;

                        case "rakeback:updated":
                            if (auth.user) {
                                auth.user.daily_rakeback =
                                    data.daily_rakeback / 100;
                                auth.user.weekly_rakeback =
                                    data.weekly_rakeback / 100;
                                auth.user.monthly_rakeback =
                                    data.monthly_rakeback / 100;
                            }
                            break;
                        case "ban":
                            if (data) {
                                await auth.fetchUser().then(() => {
                                    return router.push("/block/banned");
                                });
                            } else {
                                router.push("/");
                            }
                            break;
                        default:
                            console.log("Unknown user:message action", action);
                            break;
                    }
                }
            );

            socket.value.on("app:config", (data: AppConfig) => {
                configData.value = data;
            });

            socket.value.on("chat:count", ({ count }: { count: number }) => {
                userCount.value = count;
            });

            socket.value.on("live-games:index", (data: any) => {
                if (data.coinflip !== undefined) {
                    liveGame.value.coinflip = data.coinflip;
                }
                if (data.jackpot !== undefined) {
                    liveGame.value.jackpot = data.jackpot;
                }
                if (data.battles !== undefined) {
                    liveGame.value.battles = data.battles;
                }
            });

            socket.value.on("maintenance", data => {
                if (auth.isLogged && auth.hasPermission("maintenance_bypass")) {
                    return;
                }

                if (data) {
                    router.push("/block/maintenance");
                } else {
                    router.push("/");
                }
            });
        };

        const disconnect = () => socket.value?.disconnect();

        // allows one-time registration listening of any event
        const register = (
            event: string,
            callback: (...args: any[]) => void
        ) => {
            socket.value?.removeAllListeners(event);
            socket.value?.on(event, callback);
        };

        const handleNamedRestore = (
            uniqueName: string,
            callback: () => any,
            stopCallback: (() => any) | undefined,
            execute: boolean = true
        ) => {
            if (handleNamedCallbacks.value[uniqueName]) {
                handleNamedCallbacks.value[uniqueName].callback();
                return;
            }

            handleNamedCallbacks.value[uniqueName] = {
                callback,
                stopCallback
            };

            if (execute) {
                callback();
            }
        };

        const removeNamedRestore = (
            uniqueName: string,
            preventExecute?: boolean
        ) => {
            if (handleNamedCallbacks.value[uniqueName] && !preventExecute) {
                handleNamedCallbacks.value[uniqueName].stopCallback?.();
            }

            delete handleNamedCallbacks.value[uniqueName];
        };

        watch(
            () => auth.token,
            newValue => {
                socket.value?.emit("auth:update", newValue);
            }
        );

        return {
            load,
            disconnect,
            register,

            handleNamedRestore,
            removeNamedRestore,

            socket,
            connected,
            config: configData,
            mightBeErroring,
            userCount,
            liveGame
        };
    });
};
