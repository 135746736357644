import { defineStore } from "pinia";

export const useGameStore = defineStore("game", () => {
    const route = useRoute();
    const { t } = useNuxtApp().$i18n;
    const games = ref<GameStoreInterface[]>([]);

    const load = async () => {
        return fetchGames();
    };

    const fetchGames = async () => {
        const { data, error } = await useApi<PaginatedInterface<GameInterface>>(
            "/games?perPage=1000"
        );

        if (error.value) {
            return;
        }

        for (const game of data.value?.data ?? []) {
            let icon = game.icon;

            if (icon) {
                icon = icon.replace(/-/g, "");
                icon = icon.charAt(0).toUpperCase() + icon.slice(1);
            }

            games.value.push({
                ...game,
                name: t(`game.slug.${game.slug}`),
                to: `/games/${game.slug}`,
                component: icon
                    ? markRaw(
                        defineAsyncComponent(
                            () => import(`@/components/Icons/${icon}.vue`)
                        )
                    )
                    : undefined
            });
        }
    };

    const getGame = (slug: string) => {
        return games.value.find(game => game.slug === slug);
    };

    const getGameLink = (slug: string, gameUuid?: string) => {
        switch (slug) {
        case "wheel":
        case "jackpot":
        case "coinflip":
            return "/games/" + slug + "?game=" + gameUuid;
        case "cases":
        case "battles":
            return "/games/" + slug + "/" + gameUuid;
        default:
            return "/games/" + slug;
        }
    };

    const game = computed(() =>
        games.value.find(game => route.path.startsWith(game.to))
    );

    return {
        games,
        game,

        getGame,
        load,
        fetchGames,
        getGameLink
    };
});
