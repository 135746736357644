export default defineNuxtRouteMiddleware((to) => {
    const auth = useAuthStore();
    const toast = useCustomToast();
    const { t } = useNuxtApp().$i18n;

    if (to.path.startsWith("/dashboard") && !auth.hasPermission("access_dashboard")) {
        toast.add({
            title: t("notification.noAccessPage"),
            color: "red"
        });

        return navigateTo("/");
    }
});
